
import Swiper, { Autoplay,EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

$(function () {
  if ($('.p-burger__btn').length > 0) {
    $(".p-burger__btn").on('click', function () {
      $(this).toggleClass('active');
      $(".p-burger").toggleClass('active');
      $(".p-burger__body").toggleClass('active');
      $(".p-header__container").toggleClass('active');
    });
  }

  if ($('.p-top__notice').length > 0) {

  let scrollend = $('.p-top__notice').offset().top; //ページ上部からの距離を取得
  let distance = 0;
  let wh = $(window).height();
  if ($('.p-header__container').length > 0) {
    $(document).on('scroll', function () {
      distance = $(this).scrollTop(); //スクロールした距離を取得
      scrollend = $('.p-top__notice').offset().top;
      wh = $(window).height();
      if (scrollend <= distance) {
        $('.p-header__container').addClass('scroll-active');
      } else {
        $('.p-header__container').removeClass('scroll-active');
      }
    });
  }
}

});

let swiper = new Swiper('.swiper', {
  modules: [Autoplay, EffectFade],
  effect: 'fade',
  autoplay: true,
  loop: true,
  speed: 1000,
  fadeEffect: {
    crossFade: true
  },
  autoplay: { // 自動再生
    delay: 4500, // x後に次のスライド
    disableOnInteraction: false, // 矢印をクリックしても自動再生を止めない
  },
});
